<template>
  <h1 class="red">{{ message }}</h1>
</template>

<style lang="scss">
.red {
  color: red;
}
</style>

<script>
export default {
  data() {
    return {
      message: 'Teste'
    };
  },
};
</script>